import React, { Component } from 'react';
import PropTypes from 'prop-types';

import 'mapbox-gl/dist/mapbox-gl.css';

const ReactMapboxGl =
  typeof window !== `undefined` ? require('react-mapbox-gl').Map : null;
const Marker =
  typeof window !== `undefined` ? require('react-mapbox-gl').Marker : null;

const mapBoxToken =
  'pk.eyJ1IjoiYm9ybjA1IiwiYSI6IjJqRFhZMEkifQ.CmOs6mj8mmEPYE1qaCAjMw';
const mapBoxStyle = 'mapbox://styles/born05/cj85x59pb230m2smvarm0f33b';

export default class extends Component {
  static propTypes = {
    markerImg: PropTypes.string,
  };

  static defaultProps = {
    markerImg: '',
  };

  constructor() {
    super();

    this.map = null;

    // Only render in browser
    if (typeof window !== `undefined`) {
      this.map = ReactMapboxGl({ accessToken: mapBoxToken });
    }
  }

  render() {
    const { markerImg } = this.props;

    const Map = this.map;

    // Only render in browser
    return typeof window !== `undefined` ? (
      <Map
        // eslint-disable-next-line react/style-prop-object
        style={mapBoxStyle}
        containerStyle={{
          height: '100%',
          width: '100%',
          zIndex: 0,
        }}
        center={[5.130284, 52.089643]}
        zoom={[13.0]}
      >
        <Marker coordinates={[5.130284, 52.089643]} anchor="bottom">
          <img src={markerImg} alt="Map marker" />
        </Marker>
      </Map>
    ) : null;
  }
}
