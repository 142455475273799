import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { TweenLite, TimelineLite } from 'gsap';
import styled from 'styled-components';
import {
  EASE_IN,
  EASE_OUT,
  EASE_LINEAR,
  EASE_TITLE_SPACER,
} from '../Constants';

import breakpoint from '../constants/breakpoints';

import { p1, p2, p3, h2, h6 } from './typographyComponents';

const BodyContainer = styled.div`
  width: 100%;
  max-width: 1060px;
  position: relative;
  color: rgb(${(p) => p.textColor});

  @media (min-width: ${breakpoint.md}px) {
    min-width: 440px;
  }

  @media (min-width: ${breakpoint.lg}px) {
    width: 55%;
  }
`;

const TitleItemWrapper = styled.div`
  position: relative;
`;

const TitleFull = styled(h2)`
  position: absolute;
  top: 0;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  left: 0;
  opacity: 0;
  overflow: hidden;
`;

const TitleOutlined = styled(h2)`
  display: inline-block;
  opacity: 0;
  line-height: 1;
  white-space: nowrap;
  -webkit-text-fill-color: rgb(${(p) => p.bgColor}, 0);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(${(p) => p.textColor});
`;

const Index = styled(p3)`
  position: absolute;
  top: -4px;
  right: 100%;
  margin-right: 5px;
  opacity: 0;
  will-change: opacity;

  @media (min-width: ${breakpoint.lg}px) {
    top: -9px;
    margin-right: 8px;
  }
`;

const Subtitle = styled(h6)`
  margin: 5px 0 15px;
  opacity: 0;
  will-change: opacity;
`;

export const Body = styled(p1)`
  padding-top: 10px;
  will-change: opacity;
`;

const Quote = styled(p2)`
  opacity: 0;
  display: block;
  border-left: 2px #4a76e4 solid;
  padding-left: 25px;
  margin: 45px 0 0;
  will-change: opacity;
`;

const TitleCursor = styled.div`
  background-color: rgb(${(p) => p.textColor});
  width: 0;
  height: 53px;
  position: absolute;
  top: ${(p) => p.top}px;
  left: 0;
  will-change: transform, opacity;
`;

const TitleWrapper = styled.div`
  transform: translate3d(-10vw, 0, 0);
  will-change: transform;
  position: relative;
`;

const GradientOverlay = styled.div`
  width: 30%;
  position: absolute;
  top: ${(p) => p.top}px;
  left: -30%;
  background: linear-gradient(
    to right,
    rgba(${(p) => p.bgColor}, 0),
    rgba(${(p) => p.bgColor}, 1)
  );
  will-change: transform;

  &:after {
    content: '';
    background-color: rgb(${(p) => p.bgColor});
    height: 100%;
    width: 100vw;
    position: absolute;
    left: 100%;
  }
`;

const ChildrenContainer = styled.div`
  position: relative;
`;

export default class ContentBody extends Component {
  static propTypes = {
    children: PropTypes.node,
    quote: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.arrayOf(PropTypes.string),
    index: PropTypes.string,
    textColor: PropTypes.string,
    bgColor: PropTypes.string,
    body: PropTypes.string,

    show: PropTypes.bool,
  };

  static defaultProps = {
    children: null,
    subtitle: '',
    body: '',
    quote: '',
    title: [''],
    index: '00',
    textColor: '255,255,255',
    bgColor: '0,0,0',

    show: false,
  };

  constructor(props) {
    super(props);
    this.indexRef = createRef();
    this.subRef = createRef();
    this.bodyRef = createRef();
    this.quoteRef = createRef();
    this.titleWrapperRef = createRef();
    this.childrenRef = createRef();
    this.cursorRef = [];
    this.gradientRef = [];
    this.titleFullRef = [];
    this.titleOutRef = [];
    this.nrRows = props.title.length;

    for (let i = 0; i < this.nrRows; i++) {
      this.cursorRef[i] = createRef();
      this.gradientRef[i] = createRef();
      this.titleFullRef[i] = createRef();
      this.titleOutRef[i] = createRef();
    }

    this.titleBreaks = 0;
  }

  componentDidMount() {
    const { show } = this.props;

    for (let i = 0; i < this.nrRows; i++) {
      const height = this.titleOutRef[i].current.offsetHeight;
      this.cursorRef[i].current.style.height = `${height}px`;
      this.gradientRef[i].current.style.height = `${height}px`;
    }

    if (show) {
      this.animate();
    }
  }

  componentDidUpdate(prevProps) {
    const { show } = this.props;

    if (!prevProps.show && show) {
      this.animate();
    }
  }

  checkForBreaks = (string) =>
    string.split('').map((letter, i) => {
      const key = i;
      if (letter === '\n') {
        this.titleBreaks++;
        return <br key={key} />;
      }
      return letter === ' ' ? '\u00A0' : letter;
    });

  animate() {
    // ========== TITEL ANIMATION ==========
    if (this.titleOutRef[0].current) {
      TweenLite.to(this.indexRef.current, 0.001, {
        opacity: 1,
        delay: 0.25,
      });

      TweenLite.to(this.titleWrapperRef.current, 1.04, {
        x: 0,
        ease: EASE_OUT,
      });

      const timelines = [];
      for (let i = 0; i < this.nrRows; i++) {
        const tl = new TimelineLite({ paused: true });
        tl.set(this.titleFullRef[i].current, { opacity: 1 });
        tl.fromTo(
          this.titleFullRef[i].current,
          1.44,
          { width: 0 },
          {
            width: this.titleOutRef[i].current.offsetWidth + 25,
            ease: EASE_TITLE_SPACER,
            clearProps: 'width',
          },
          0.16,
        );

        tl.to(
          this.cursorRef[i].current,
          1.44,
          {
            x: this.titleOutRef[i].current.offsetWidth + 25,
            ease: EASE_TITLE_SPACER,
          },
          0.16,
        );

        tl.to(
          this.cursorRef[i].current,
          0.24,
          {
            width: 26,
            ease: EASE_IN,
          },
          0.16,
        );

        tl.to(
          this.cursorRef[i].current,
          0.52,
          {
            width: 0,
            ease: EASE_OUT,
          },
          1.08,
        );

        if (window.innerWidth >= breakpoint.lg) {
          this.titleOutRef[i].current.style.opacity = '1';
          tl.to(
            this.gradientRef[i].current,
            0.35,
            {
              x:
                this.gradientRef[i].current.offsetWidth +
                this.titleOutRef[i].current.offsetWidth -
                50,
              ease: EASE_LINEAR,
            },
            0,
          );

          tl.to(
            this.gradientRef[i].current,
            0.05,
            {
              opacity: 0,
              ease: EASE_LINEAR,
            },
            0.35,
          );
        } else {
          this.gradientRef[i].current.style.opacity = '0';
          tl.to(
            this.titleOutRef[i].current,
            0.5,
            {
              opacity: 1,
            },
            0,
          );
        }

        timelines.push(tl);
      }

      timelines[0].play();
      for (let i = 1; i < timelines.length; i++) {
        setTimeout(() => {
          timelines[i].play();
        }, i * 250);
      }
    }

    // ========== SUBTITLE ANIMATION  ==========
    if (this.subRef.current) {
      TweenLite.to(this.subRef.current, 0.72, {
        opacity: 1,
        ease: EASE_IN,
        delay: 0.56,
      });
    }

    // ========== BODY ANIMATION ==========
    if (this.bodyRef.current) {
      TweenLite.fromTo(
        this.bodyRef.current,
        0.72,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          delay: 0.6,
          ease: EASE_IN,
        },
      );
    }

    // ========== QUOTE ANIMATION ==========
    if (this.quoteRef.current) {
      TweenLite.to(this.quoteRef.current, 0.72, {
        opacity: 1,
        delay: 0.6,
        ease: EASE_IN,
      });
    }

    // ======== children animation ========
    if (this.childrenRef.current) {
      TweenLite.fromTo(
        this.childrenRef.current,
        0.72,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          delay: 0.6,
          ease: EASE_IN,
        },
      );
    }
  }

  render() {
    const {
      index,
      textColor,
      bgColor,
      title,
      subtitle,
      body,
      quote,
      children,
    } = this.props;

    return (
      <BodyContainer textColor={textColor}>
        {title.length ? (
          <TitleWrapper ref={this.titleWrapperRef}>
            <Index ref={this.indexRef}>{index}</Index>

            {title.map((row, i) => {
              const key = i * 10;
              return (
                <TitleItemWrapper key={key}>
                  <TitleOutlined
                    bgColor={bgColor}
                    textColor={textColor}
                    ref={this.titleOutRef[i]}
                  >
                    {row}
                  </TitleOutlined>

                  <GradientOverlay
                    ref={this.gradientRef[i]}
                    bgColor={bgColor}
                    top={i * 60}
                  />

                  <TitleFull ref={this.titleFullRef[i]}>{row}</TitleFull>

                  <TitleCursor
                    top={i * 60}
                    textColor={textColor}
                    ref={this.cursorRef[i]}
                  />
                </TitleItemWrapper>
              );
            })}
          </TitleWrapper>
        ) : null}

        {subtitle.length ? (
          <Subtitle ref={this.subRef}>{subtitle}</Subtitle>
        ) : null}

        {body.length ? <Body ref={this.bodyRef}>{body}</Body> : null}

        {quote.length ? <Quote ref={this.quoteRef}>{quote}</Quote> : null}

        <ChildrenContainer ref={this.childrenRef}>{children}</ChildrenContainer>
      </BodyContainer>
    );
  }
}
