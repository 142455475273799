import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import { TweenLite, Power4 } from 'gsap';

import {
  HamburgerButton,
  CrossButton,
} from '../../components/buttonComponents';
import breakpoint from '../../constants/breakpoints';
import Link from '../../components/link';
import HomeLogo from '../../components/newbornLogo/newbornIcon';
import { zIndex } from '../../constants/styling';

import newbornLogoFull from './newbornLogoFull.png';
import aceLogo from './ace.png';

const Nav = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndex.nav};
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
`;

const NavMenu = styled.div`
  background-color: #4a76e4;
  height: 100%;
  width: 100vw;
  padding: 7vh 12vw;
  right: 0;
  position: absolute;
  transform: translateX(115%);
  pointer-events: auto;

  @media (min-width: ${breakpoint.md}px) {
    width: 380px;
    padding: 7vh 4vw;
  }

  @media (min-width: ${breakpoint.lg}px) {
    width: 460px;
    right: 60px;
  }
`;

const NavMenuItemWrapper = styled.div`
  color: #fff;
  display: flex;
  padding: 10px 0;
  width: 100%;
`;

const NavMenuItem = styled.h1`
  font-size: 40px;
  font-weight: bold;
  height: 48px;
  cursor: pointer;

  &:hover {
    border-bottom: 2px #fff solid;
  }
`;

const NavMenuItemIndex = styled.span`
  padding-top: 4px;
  margin-right: 8px;
  font-weight: bold;
`;

const NewbornLogo = styled.img`
  width: 50%;
  position: absolute;
  bottom: 7%;
  left: 25%;
`;

const NavBar = styled.div`
  pointer-events: auto;
  display: flex;
  justify-content: center;
  width: 60px;
  background-color: transparent;
  height: 100px;
  right: 20px;
  position: absolute;
  top: 0;
  overflow: hidden;
  padding: 30px 0;

  @media (min-width: ${breakpoint.lg}px) {
    background-color: #000000;
    height: 100%;
    right: 0;
  }
`;

const BornLogo = styled.img`
  display: none;
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: 30px;
  left: 27%;

  @media (min-width: ${breakpoint.lg}px) {
    display: block;
  }
`;

const PoweredBy = styled.h5`
  display: none;
  color: #fff;
  font-size: 14px;
  position: absolute;
  bottom: 80px;
  font-weight: normal;
  white-space: nowrap;

  div {
    transform: rotate(90deg) translateX(-50%);
    transform-origin: center;
  }

  span {
    color: #4a76e4;
  }

  @media (min-width: ${breakpoint.lg}px) {
    display: block;
  }
`;

const CloseButton = styled(CrossButton)`
  top: 30px;
  transform: translate(-150%, 0%);
  right: 15px;
  height: 40px;
  width: 40px;

  @media (min-width: ${breakpoint.lg}px) {
    top: 25px;
    right: 10px;
  }
`;

const OpenButton = styled(HamburgerButton)`
  top: 30px;
  right: 15px;
  height: 34px;
  width: 40px;

  span {
    top: 15px;
  }

  @media (min-width: ${breakpoint.lg}px) {
    right: 10px;
  }
`;

export default class NavSection extends Component {
  navigationItems = [
    'About',
    'Ventures',
    'Principles',
    'Approach',
    'Program',
    'Contact',
  ];

  constructor(props) {
    super(props);

    this.menuOpen = false;
    this.menuRef = createRef();
    this.openButtonRef = createRef();
    this.closeButtonRef = createRef();

    this.scrollTo = this.scrollTo.bind(this);
  }

  toggleNav(e, open = null) {
    if (e) e.preventDefault();
    if (open == null) open = !this.menuOpen;
    this.menuOpen = open;

    TweenLite.to(this.menuRef.current, 1, {
      x: open ? '0%' : '100%',
      ease: Power4.easeOut,
    });
    TweenLite.to(this.openButtonRef.current, 0.5, {
      x: open ? '-150%' : '0%',
      ease: Power4.easeOut,
    });
    TweenLite.to(this.closeButtonRef.current, 0.5, {
      x: open ? '0%' : '150%',
      ease: Power4.easeOut,
    });
  }

  scrollTo(label) {
    const el = document.getElementById(label);

    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
      this.toggleNav(null, false);
    }
  }

  render() {
    return (
      <Nav>
        <HomeLogo />
        <NavMenu ref={this.menuRef}>
          <NavMenuItemWrapper>
            <NavMenuItemIndex>01</NavMenuItemIndex>

            <NavMenuItem
              onClick={() => {
                this.scrollTo('about');
              }}
            >
              About
            </NavMenuItem>
          </NavMenuItemWrapper>

          <NavMenuItemWrapper>
            <NavMenuItemIndex>02</NavMenuItemIndex>

            <NavMenuItem
              onClick={() => {
                this.scrollTo('ventures');
              }}
            >
              Ventures
            </NavMenuItem>
          </NavMenuItemWrapper>

          <NavMenuItemWrapper>
            <NavMenuItemIndex>03</NavMenuItemIndex>

            <NavMenuItem
              onClick={() => {
                this.scrollTo('principles');
              }}
            >
              Principles
            </NavMenuItem>
          </NavMenuItemWrapper>

          <NavMenuItemWrapper>
            <NavMenuItemIndex>04</NavMenuItemIndex>

            <NavMenuItem
              onClick={() => {
                this.scrollTo('approach');
              }}
            >
              Approach
            </NavMenuItem>
          </NavMenuItemWrapper>

          <NavMenuItemWrapper>
            <NavMenuItemIndex>05</NavMenuItemIndex>

            <NavMenuItem
              onClick={() => {
                this.scrollTo('program');
              }}
            >
              Program
            </NavMenuItem>
          </NavMenuItemWrapper>

          <NavMenuItemWrapper>
            <NavMenuItemIndex>06</NavMenuItemIndex>

            <NavMenuItem
              onClick={() => {
                this.scrollTo('newsroom');
              }}
            >
              Newsroom
            </NavMenuItem>
          </NavMenuItemWrapper>

          <NavMenuItemWrapper>
            <NavMenuItemIndex>07</NavMenuItemIndex>

            <NavMenuItem
              onClick={() => {
                this.scrollTo('contact');
              }}
            >
              Contact
            </NavMenuItem>
          </NavMenuItemWrapper>

          <NewbornLogo src={newbornLogoFull} />
        </NavMenu>
        <NavBar>
          <OpenButton
            ref={this.openButtonRef}
            onClick={this.toggleNav.bind(this)}
          >
            <span />
          </OpenButton>
          <CloseButton
            ref={this.closeButtonRef}
            onClick={this.toggleNav.bind(this)}
          />

          <PoweredBy>
            <div>
              An &nbsp;<span>Ace</span>&nbsp; agency
            </div>
          </PoweredBy>

          <Link to="https://www.thisisace.nl">
            <BornLogo src={aceLogo} />
          </Link>
        </NavBar>
      </Nav>
    );
  }
}
