import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { TimelineLite } from 'gsap';

import styling from '../constants/styling';
import breakpoint from '../constants/breakpoints';

const LetterAnimation = styled.div`
  width: 100%;
  padding-top: 100%;
  display: flex;
  justify-content: center;
  height: 290px;
  position: relative;

  svg {
    position: absolute;
    width: 100%;
    height: auto;
    font: normal 8em ${styling.fontFamilyAxiforma};
    text-transform: uppercase;
    top: 0;
    left: 0;
    right: 0;
  }

  @media (min-width: ${breakpoint.lg}px) {
    height: auto;
  }
`;

const LetterSvg = styled.svg`
  width: 100;
  height: 100%;
`;

const SvgText = styled.text`
  stroke-linejoin: round;
  stroke: #4a76e4;
  stroke-width: 0.5px;
  opacity: 0;
`;

const SvgImage = styled.image`
  opacity: 0;
`;

export default class AnimationLetter extends Component {
  static propTypes = {
    letter: PropTypes.string,
    bgImg: PropTypes.string,

    show: PropTypes.bool,
  };

  static defaultProps = {
    letter: 'NULL',
    bgImg: '',

    show: false,
  };

  constructor() {
    super();

    this.textRef = createRef();
    this.imageRef = createRef();
  }

  componentDidMount() {
    const { show } = this.props;

    if (show) {
      this.animate();
    }
  }

  componentDidUpdate(prevProps) {
    const { show } = this.props;

    if (!prevProps.show && show) {
      this.animate();
    }
  }

  generateId(length = 6) {
    let id = '';
    const possibilities =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
      id += possibilities.charAt(
        Math.floor(Math.random() * possibilities.length),
      );
    }

    return id;
  }

  animate() {
    const timeline = new TimelineLite();
    timeline
      .staggerTo(
        [this.textRef.current, this.imageRef.current],
        1,
        { opacity: 1 },
        0.5,
      )
      .to(this.textRef.current, 0.5, { strokeOpacity: 0 });
  }

  render() {
    const { bgImg, letter } = this.props;
    const randomId = this.generateId();
    return (
      <LetterAnimation ref={this.containerRef}>
        <LetterSvg viewBox="0 0 100 100">
          <pattern
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            id={randomId}
            width="100%"
            height="100%"
            x="4%"
            y="21%"
          >
            <SvgImage
              ref={this.imageRef}
              xlinkHref={bgImg}
              width="115%"
              height="115%"
            />
          </pattern>

          <SvgText
            ref={this.textRef}
            textAnchor="middle"
            x="50%"
            y="50%"
            dy=".37em"
            style={{ fill: `url(#${randomId})` }}
          >
            {letter}
          </SvgText>
        </LetterSvg>
      </LetterAnimation>
    );
  }
}
