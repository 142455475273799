import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import breakpoint from '../../constants/breakpoints';
import Link from '../../components/link';
import { p1, p2, h2, h6 } from '../../components/typographyComponents';
import { closeModalAction } from '../../state/appReducer';
import buttonReset from '../../styling/buttonReset';
import { zIndex } from '../../constants/styling';

const ComponentContainer = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  z-index: ${zIndex.overlay};
  background-color: #000;
  transition: transform 1s;
  outline: 0;

  &:not(.is-active) {
    transform: translate3d(100%, 0, 0);
  }

  @media (min-width: ${breakpoint.lg}px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 67vw;
  object-fit: cover;

  @media (min-width: ${breakpoint.lg}px) {
    width: 50%;
    height: 100%;
  }
`;

const BackButton = styled.span`
  position: absolute;
  display: inline-block;
  top: 30px;
  right: 25px;
  width: 50px;
  height: 50px;
  overflow: hidden;

  @media (min-width: ${breakpoint.md}px) {
    right: 45px;
    top: 45px;
  }

  &:hover {
    cursor: pointer;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    margin-top: -2px;
    background: #fff;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

const ContentGrid = styled.div`
  width: 100%;
  max-width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (min-width: ${breakpoint.lg}px) {
    width: 84vw;
    height: 29vw;
    max-height: 100vh;
    flex-direction: row;
  }
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 3vh 7vw;

  @media (min-width: ${breakpoint.lg}px) {
    width: 40%;
    padding: 0 0 0 5vw;
  }
`;

const VideoContainer = styled.div`
  width: 95%;
  padding: 20px;
  position: relative;
  margin-top: 110px;
  margin-left: 7%;

  @media (min-width: ${breakpoint.lg}px) {
    width: 60%;
    margin-top: auto;
    margin-left: auto;
  }
`;

const VideoPlaceholder = styled.div`
  padding-top: ${(100 / 16) * 9}%;
`;

const CampaignVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  background-color: #000;
  transition: opacity 0.3s linear;

  &:hover {
    cursor: pointer;
  }

  &.is-ready {
    opacity: 1;
  }
`;

const VideoButton = styled.button`
  ${buttonReset}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.4s ease;

  &.is-ready {
    opacity: 1;
  }

  &.is-playing {
    opacity: 0;
  }

  svg {
    position: absolute;
    right: 38%;
    bottom: 38%;
    width: 26%;
    height: 26%;
  }
`;

const Title = h2;

const Subtitle = styled(h6)`
  margin: 0 0 8px;
  width: 100%;

  @media (min-width: ${breakpoint.lg}px) {
    margin: 5px 0px 35px;
  }
`;

const Body = styled(p1)`
  white-space: pre-line;
`;

const Rule = styled.hr`
  padding: 0;
  border: 0;
  border-bottom: 1px #979797 solid;
  margin: 45px 0 20px;
  width: 100%;

  @media (min-width: ${breakpoint.lg}px) {
    width: 75%;
  }
`;

const Steps = styled(p1)`
  font-weight: bold;
  color: #aec4fb;
`;

const WebsiteLink = styled(p2)`
  a {
    margin-left: 10px;
  }

  svg {
    width: 14px;
    fill: #fff;
  }
`;

const Clients = styled.div`
  margin-top: 55px;
  font-weight: bold;
  font-size: 20px;
  color: #6c6c6c;

  img {
    margin-top: 35px;
    width: 100%;
  }
`;

const drawSteps = (steps) => {
  if (steps !== undefined && steps.length > 0) {
    let [string] = steps;

    for (let i = 1; i < steps.length; i += 1) {
      string = `${string} • ${steps[i]}`;
    }

    return string;
  }

  return null;
};

const mapStateToProps = (state) => ({
  modalOpen: state.modalOpen,
  content: state.modalData,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(closeModalAction());
  },
});

class CampaignOverlay extends Component {
  static propTypes = {
    content: PropTypes.shape({
      background: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      body: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape(),
      ]),
      processSteps: PropTypes.array,
      email: PropTypes.string,
      btnLabel: PropTypes.string,
      websiteUrl: PropTypes.string,
      clientsImg: PropTypes.string,
      video: PropTypes.string,
    }),
    modalOpen: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    content: null,
    modalOpen: null,
  };

  constructor() {
    super();

    this.state = {
      canPlay: false,
      playing: false,
    };

    this.containerRef = createRef();
    this.videoRef = createRef();
    this.playButtonRef = createRef();

    this.keyDownHander = this.keyDownHander.bind(this);
    this.backButtonHandler = this.backButtonHandler.bind(this);
    this.videoClickHandler = this.videoClickHandler.bind(this);
    this.onCanPlay = this.onCanPlay.bind(this);
    this.onPlay = this.onPlay.bind(this);
    this.onPause = this.onPause.bind(this);
  }

  componentDidMount() {
    const { modalOpen } = this.props;

    if (modalOpen === 'campaign') this.animateModal(true);
  }

  componentDidUpdate(prevProps) {
    const { content, modalOpen } = this.props;
    // const { playing } = this.state;

    // eslint-disable-next-line react/prop-types
    if (modalOpen === 'campaign' && content.video && (typeof prevProps.content === 'undefined')) {
      this.videoRef.current.load();
    }
  }

  onCanPlay() {
    this.setState({ canPlay: true });
  }

  onPlay() {
    this.setState({ playing: true });
  }

  onPause() {
    this.setState({ playing: false });
  }

  backButtonHandler() {
    this.animateModal(false);
  }

  keyDownHander(e) {
    if (e.key === 'Escape') {
      this.animateModal(false);
    }
  }

  videoClickHandler() {
    const { playing } = this.state;

    if (playing) {
      this.pauseVideo();
    } else {
      this.playVideo();
    }
  }

  playVideo() {
    if (this.videoRef.current) this.videoRef.current.play();
  }

  pauseVideo() {
    if (this.videoRef.current) this.videoRef.current.pause();
  }

  animateModal(animateIn) {
    const { closeModal } = this.props;
    const overlay = this.containerRef.current;

    if (!overlay) return;

    if (!animateIn) {
      this.pauseVideo();
      closeModal();
    }
  }

  render() {
    const { content, modalOpen } = this.props;
    const { canPlay, playing } = this.state;

    const isOpen = modalOpen === 'campaign';

    return (
      <ComponentContainer
        ref={this.containerRef}
        onKeyDown={this.keyDownHander}
        tabIndex="0"
        className={isOpen ? 'is-active' : ''}
      >
        {!isOpen ? null : (
          <>
            <BackgroundImage src={content.background} />

            <ContentGrid>
              <DetailContainer>
                <Title>{content.title}</Title>
                <Subtitle>{content.subtitle}</Subtitle>
                {typeof content.body !== 'string' ? (
                  <Body dangerouslySetInnerHTML={content.body} />
                ) : (
                  <Body>{content.body}</Body>
                )}
                <Rule />

                {content.processSteps ? (
                  <Steps>Current phase: {drawSteps(content.processSteps)}</Steps>
                ) : null}

                {(content.email && content.email.length) || (content.websiteUrl && content.websiteUrl.length) ? (
                  <WebsiteLink>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 15.489 12.475"
                    >
                      <polygon points="8.686 0 7.334 1.475 11.438 5.236 0 5.236 0 7.236 11.44 7.236 7.334 11 8.686 12.475 15.489 6.237 8.686 0" />
                    </svg>

                    {content.email && content.email.length ? (
                      <Link to={`mailto:${content.email}`}>
                        {content.btnLabel ? content.btnLabel : 'Contact us'}
                      </Link>
                    ) : null}

                    {content.websiteUrl && content.websiteUrl.length ? (
                      <Link to={content.websiteUrl} target="_blank">
                        {content.btnLabel ? content.btnLabel : 'Visit website'}
                      </Link>
                    ) : null}
                  </WebsiteLink>
                ) : null}

                {content.clientsImg ? (
                  <Clients>
                    Some of our corporate clients
                    <img src={content.clientsImg} alt="" />
                  </Clients>
                ) : null}
              </DetailContainer>

              <VideoContainer>
                {content.video && content.video.length ? (
                  <>
                    <CampaignVideo
                      ref={this.videoRef}
                      className={`${playing ? 'is-playing' : ''} ${
                        canPlay ? 'is-ready' : ''
                      }`}
                      src={content.video}
                      onCanPlay={this.onCanPlay}
                      onPlay={this.onPlay}
                      onPause={this.onPause}
                      onEnded={this.onPause}
                    />

                    <VideoButton
                      type="button"
                      ref={this.playButtonRef}
                      className={`${playing ? 'is-playing' : ''} ${
                        canPlay ? 'is-ready' : ''
                      }`}
                      onClick={this.videoClickHandler}
                    >
                      <svg
                        width="123px"
                        height="123px"
                        viewBox="0 0 123 123"
                        version="1.1"
                      >
                        <circle fill="#4A76E4" cx="61.5" cy="61.5" r="61.5" />
                        <polygon
                          fill="#FFFFFF"
                          transform="translate(66.000000, 63.000000) rotate(90.000000) translate(-66.000000, -63.000000)"
                          points="66 52 77 74 55 74"
                        />
                      </svg>
                    </VideoButton>
                  </>
                ) : (
                  <VideoPlaceholder />
                )}
              </VideoContainer>
            </ContentGrid>

            <BackButton onClick={this.backButtonHandler} />
          </>
        )}
      </ComponentContainer>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignOverlay);
