import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ViewportParallax from '../../utils/ViewportParallax';
import breakpoint from '../../constants/breakpoints';
import styling from '../../constants/styling';
import buttonReset from '../../styling/buttonReset';
import AnimationLetter from '../../components/animationLetter';
import ContentBody, { Body as Paragraph } from '../../components/contentBody';
import { h4 } from '../../components/typographyComponents';
import { openModalAction } from '../../state/appReducer';

import letterGif from '../../../static/letterBgVideo.gif';
import aboutImage from './about-img.jpg';

import startupsBgImg from './startups-bg.jpg';
import corporatesBgImg from './corporates-bg.jpg';
import corporatesImg from './corporates-logos.png';
import investorsBgImg from './investors-bg.jpg';

const AboutContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  position: relative;
  align-items: center;

  @media (min-width: ${breakpoint.lg}px) {
    padding-top: 150px;
    max-width: 1366px;
  }
`;

const AboutContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 0;
  width: 100%;
  padding: 250px 30px 60px;
  max-width: 100%;

  @media (min-width: ${breakpoint.md}px) {
    padding: 190px 40px 60px 0;
    width: calc(100% - 200px);
    max-width: 500px;
    margin: 0 100px;
  }

  @media (min-width: ${breakpoint.lg}px) {
    padding: 0 50px 100px 150px;
    width: 100%;
    margin: auto;
    max-width: none;
    align-items: center;
    flex-direction: row;
  }
`;

const Chapters = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 15px 30px;

  @media (min-width: ${breakpoint.md}px) {
    flex-direction: row;
    padding: 0 25px 30px;
  }

  @media (min-width: ${breakpoint.lg}px) {
    padding: 0 45px 70px 135px;
  }
`;

const Chapter = styled.div`
  padding: 0 15px;
  margin-bottom: 30px;

  @media (min-width: ${breakpoint.md}px) {
    width: ${100 / 3}%;
  }
`;

const ChapterButton = styled.button`
  ${buttonReset}
  width: 100%;
  text-align: left;
  padding: 0 0 30px;
  color: ${styling.white};
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 10px;
    width: 100%;
    background: ${styling.blue};
  }

  &::after {
    background: ${styling.white};
    transition: transform 0.3s ease-out;
    transform-origin: left bottom;
    transform: scale(0, 1);
  }

  &:hover::after {
    transform: scale(1, 1);
  }
`;

const ChapterTitle = styled(h4)`
  font-size: 32px;
  line-height: 1.125;
`;

const ChapterSubtitle = styled.h6`
  font-size: 16px;
  line-height: 1.5;
`;

const AboutFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: #1a1a1a;

  @media (min-width: ${breakpoint.lg}px) {
    background-color: transparent;
  }
`;

const AboutFooterWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 0 70px 30px;
  flex-direction: column-reverse;
  justify-content: space-between;

  @media (min-width: ${breakpoint.md}px) {
    padding: 0;
    width: calc(100% - 200px);
    max-width: 500px;
    margin: 0 100px;
  }

  @media (min-width: ${breakpoint.lg}px) {
    max-width: 100%;
    height: 520px;
    flex-direction: row;
    padding: 0 150px;
    margin: 0;
    width: 100%;
  }
`;

const Description = styled(Paragraph)`
  width: 100%;
  color: #b6b6b6;
  padding: 25px 25px 25px 0;
  align-self: flex-end;

  @media (min-width: ${breakpoint.lg}px) {
    width: 300px;
    padding: 0 50px 60px 0;
  }
`;

const AboutImage = styled.img`
  width: 100%;
  height: 253px;
  object-fit: cover;
  object-position: 100% 50%;

  @media (min-width: ${breakpoint.lg}px) {
    position: absolute;
    left: 40%;
    right: 0;
    width: 60%;
    padding: 0 25px 0 0;
    height: 600px;
  }
`;

const AboutLetterContainer = styled.div`
  width: 100%;
  position: absolute;
  right: -32px;
  max-width: 400px;
  top: 0;

  @media (min-width: ${breakpoint.md}px) {
    right: -26px;
  }

  @media (min-width: ${breakpoint.lg}px) {
    position: relative;
    max-width: 480px;
    min-width: 370px;
    width: 100%;
    right: auto;
    top: auto;
    left: -36px;
  }
`;

class AboutSection extends Component {
  static propTypes = {
    openModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.elementRef = createRef();

    this.state = {
      intro: false,
    };

    this.introHandler = this.introHandler.bind(this);
    this.chapterHandler = this.chapterHandler.bind(this);
  }

  introHandler() {
    this.setState({
      intro: true,
    });
  }

  chapterHandler(handle) {
    const { openModal } = this.props;

    let data = {};
    switch (handle) {
      case 'corporates':
        data = {
          title: 'For Corporate Startups',
          subtitle: 'Launch your speedboat',
          body: {
            __html:
              'Do you have a promising idea for your company? Are you looking for a disruptive and different direction? But do you need an environment to speed up innovation in a way that fits your team or company? Let’s test the waters together. Backed by a powerhouse of talents, we can guide you through every stage in the process. We are able to strategically involve the right people when needed with a high level of agility. With a strong focus on validation methodologies and growth, we properly scale up your ideas. Wherever you want to take them.<br /><br />Kickstart your next business idea.',
          },
          websiteUrl: 'https://b05.typeform.com/to/KVbbNz',
          btnLabel: 'Get in touch',
          clientsImg: corporatesImg,
          background: corporatesBgImg,
        };
        break;
      case 'investors':
        data = {
          title: 'For Investors',
          subtitle: 'Never miss a promising idea',
          body: {
            __html:
              'Newborn is a nursery for ideas and startups. We believe success is about putting the right people in the right place. Investors play a crucial role in this team. Would you like to be connected to promising ideas and entrepreneurs, by becoming part of our investment network? Let’s talk. And of course, if you want to invest in your own new or existing startup, we can support you from ideation, validation to product development, so you can confidently move forward.<br /><br />Become a part of our network.',
          },
          websiteUrl: 'https://b05.typeform.com/to/KVbbNz',
          btnLabel: 'Get in touch',
          clientsImg: null,
          background: investorsBgImg,
        };
        break;
      case 'startups':
      default:
        data = {
          title: 'For startups',
          subtitle: 'Let’s make business',
          body: {
            __html:
              'As part of Ace, Newborn able to invest in promising early stage startups with strategic and creative capital. You are supported by a multidisciplinary, award-winning team and vast network of investors and partners. We are dedicated and agile during the different stages of starting a new business. Together, we establish your strategy, build a brand, shape your product, develop marketing campaigns and maximize growth by using data-driven growth-hacking.<br /><br />Kickstart your startup.',
          },
          websiteUrl: 'https://b05.typeform.com/to/KVbbNz',
          btnLabel: 'Get in touch',
          clientsImg: null,
          background: startupsBgImg,
        };
        break;
    }

    openModal(data);
  }

  render() {
    const { intro } = this.state;
    return (
      <ViewportParallax introHandler={this.introHandler}>
        {(elementRef) => (
          <AboutContainer ref={elementRef} id="about">
            <AboutContent>
              <AboutLetterContainer>
                <AnimationLetter
                  bgImg={letterGif}
                  uniqueId="aboutLetter"
                  letter="N"
                  show={intro}
                />
              </AboutLetterContainer>

              <ContentBody
                show={intro}
                index="01"
                title={['Meet Newborn']}
                subtitle="We back, build and accelerate (corporate) startups"
                align="right"
              >
                <Paragraph>
                  Our Hybrid Venture Studio is here to guide innovation and
                  nurture sustainable growth for (corporate) startups.
                  Newborn is part of Ace, a family of leading agencies on a mission to make impact.
                  We combine high-end strategic, creative and production experience, with lean
                  startup and venture building expertise.
                </Paragraph>
                <Paragraph>
                  Join our Startup Program and be boosted by our skilled team of
                  experts, which we can deploy flexibly in every phase of the
                  startup. From ideation and validation, to product development
                  and marketing. With our vast network of investors and
                  partners, joining our program brings great potential for
                  future growth. Together, we build a strong foundation for your
                  venture.
                </Paragraph>
              </ContentBody>
            </AboutContent>

            <Chapters>
              <Chapter>
                <ChapterButton
                  type="button"
                  onClick={() => this.chapterHandler('corporates')}
                >
                  <ChapterTitle>Corporate Startups</ChapterTitle>
                  <ChapterSubtitle>Launch your speedboat</ChapterSubtitle>
                </ChapterButton>
              </Chapter>

              <Chapter>
                <ChapterButton
                  type="button"
                  onClick={() => this.chapterHandler('startups')}
                >
                  <ChapterTitle>Startups</ChapterTitle>
                  <ChapterSubtitle>Let’s make business</ChapterSubtitle>
                </ChapterButton>
              </Chapter>

              <Chapter>
                <ChapterButton
                  type="button"
                  onClick={() => this.chapterHandler('investors')}
                >
                  <ChapterTitle>Investors</ChapterTitle>
                  <ChapterSubtitle>Never miss a promising idea</ChapterSubtitle>
                </ChapterButton>
              </Chapter>
            </Chapters>

            <AboutFooter>
              <AboutFooterWrapper>
                <Description>
                  {/* <Index>01</Index> */}
                  Newborn is part of {' '}
                  <a
                    href="https://www.thisisace.nl/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ace
                  </a>, a family of leading agencies on a mission to make impact.
                </Description>
                <AboutImage src={aboutImage} />
              </AboutFooterWrapper>
            </AboutFooter>
          </AboutContainer>
        )}
      </ViewportParallax>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => {
    dispatch(openModalAction('campaign', data));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(AboutSection);
