import { Linear } from 'gsap';

import CustomEase from './vendor/CustomEase';

export const EASE_TITLE_SPACER = CustomEase.create(
  'EASE_TITLE_SPACER',
  '0.167, 0.167, 0.314, 1',
);
export const EASE_IN_OUT = CustomEase.create('EASE_IN_OUT', '0.6, 0, 0.6, 1');
export const EASE_IN = CustomEase.create('EASE_IN', '0.633, 0, 0.833, 0.833');
export const EASE_OUT = CustomEase.create('EASE_OUT', '0.4, 1, 0.4, 1');
export const EASE_LINEAR = Linear.easeNone;
export const THIN_T_POLYGON =
  '388.3,121.6 388.3,4.6 399.5,4.6 399.5,121.6 395.9,121.6 395.9,485 392.1,485 392.1,121.6';
export const THICK_T_POLYGON =
  '10.8,121.6 10.8,4.6 399.5,4.6 399.5,121.6 266.9,121.6 266.9,485 144.1,485 144.1,121.6';
export const THIN_N_POLYGON =
  '429.3,484.3 430.9,484.3 88.6,11.4 89.1,484.3 85.9,484.3 85.9,4.2 87.6,4.1 426.5,472.1 426.3,4.3 429.3,4.2';
export const THICK_N_POLYGON =
  'polygon(0 0,22% 0,72% 59%,72% 0%,100% 0%,100% 100%,71% 100%,28% 45%,27% 100%,0 100%)';
// '430.9,484.3 332.4,484.3 120.5,226.4 120.7,484.3 2.5,484.3 2.5,4.2 89.1,4.1 312.9,265.3 312.9,4.3 430.9,4.2';
export const THICK_C_PATH =
  'M.25,226.119C.25,92.111,101.343-.166,224.184-.166,304.118-.166,371.71,35.1,410.5,85.646l-69.942,73.469c-28.212-30.563-65.241-54.073-109.323-54.073-65.828,0-117.55,50.546-117.55,120.489,0,68.18,51.134,118.138,116.962,118.138,43.495,0,83.461-22.334,111.674-55.836L415.2,357.775c-37.616,49.959-107.558,90.514-191.607,90.514C98.4,448.289.25,358.951.25,226.119Z';
