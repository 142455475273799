import heyImg from './Hey.png';
import emorialImg from './Emorial.png';
import tailieImg from './Tailie.png';
import champImg from './Champ.png';
import champbg from './champ_bg.jpg';
import tailiebg from './tailie_bg.jpg';
import heybg from './hey_bg.jpg';
import emorialbg from './emorial_bg.jpg';

export default [
  {
    img: champImg,
    slideColor: '#ED674A',
    title: 'Champ',
    slug: 'champ',
    subtitle: 'Your starting point for sports',
    body:
      'Champ brings sports and athletes together. From a separate lesson to a weekly session... With Champ you can book sports whenever, wherever. Without subscriptions or commitments. Meet new people, involve friends, track your activities in your personal logbook, share your achievements and challenge and motivate each other. With Champ we want to create the largest sports community in the Netherlands. \n \n Champ, your starting point for sports.',
    processSteps: ['Commitment / Validation'],
    websiteUrl: 'https://www.champ.nl',
    background: champbg,
    video: 'https://newborn-website.ams3.cdn.digitaloceanspaces.com/static/champ_vid.mp4',
  },
  {
    img: heyImg,
    slideColor: '#DFC9C0',
    title: 'HEY',
    slug: 'hey',
    subtitle: 'Sending touch over distance',
    body:
      'Everyone wants to be close to their loved ones. But sometimes there’s just too much distance. It becomes impossible to hold that one special person. HEY bracelet lets you feel your loved one, no matter the distance. It’s the first wearable that mimics a real human touch. Not with a mechanical vibration or buzzing sensation, but an actual gentle squeeze. HEY will forever change the way people stay in contact. \n \n A revolutionary bracelet that sends touch over distance.',
    processSteps: ['Scaling'],
    websiteUrl: 'https://www.heybracelet.com/',
    background: heybg,
    video: 'https://newborn-website.ams3.cdn.digitaloceanspaces.com/static/hey_vid.mp4',
  },
  {
    img: tailieImg,
    slideColor: '#43639B',
    title: 'Tailie',
    slug: 'tailie',
    subtitle: 'Your size only',
    body:
      'Tailie lets you measure every body part with very high accuracy and matches your sizes with the products you want. Giving you a personal size passport to all connected shops. Whether you’re shopping for a suit, a dress, a winter coat or some tights, with Tailie your choice will always be right. \n \n Tailie, your size only. ',
    processSteps: ['Go to market'],
    websiteUrl: 'https://www.tailie.nl/',
    background: tailiebg,
    video: 'https://newborn-website.ams3.cdn.digitaloceanspaces.com/static/tailie_vid.mp4',
  },
  {
    img: emorialImg,
    slideColor: '#776FA5',
    title: 'Emorial',
    slug: 'emorial',
    subtitle: 'Share memories',
    body:
      'Emorial helps to find, collect and share valuable memories. We do this by offering an online yet private page. Here relatives, together with friends, neighbors or colleagues can save and share memories. Where, when and with whomever they want.',
    processSteps: ['Go to market'],
    // websiteUrl: 'https://www.emorial.com/',
    background: emorialbg,
    video: 'https://newborn-website.ams3.cdn.digitaloceanspaces.com/static/emorial_vid.mp4',
  },
];
