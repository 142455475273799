import React, { createRef, Component } from 'react';
import styled from 'styled-components';
import Link from '../../components/link';
import aceLogo from './ace.png';
// import { h6 } from '../../components/typographyComponents';
import breakpoint from '../../constants/breakpoints';
import styling from '../../constants/styling';

const HeaderContainer = styled.section`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  position: relative;
  height: calc(100vh - 75px);

  @media (min-width: ${breakpoint.lg}px) {
    height: 100vh;
  }
`;

const BackgroundVideoContainer = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  width: auto;
  bottom: 0;
  overflow: hidden;
  filter: brightness(75%);
  left: 30px;

  @media (min-width: ${breakpoint.md}px) {
    left: 100px;
  }

  @media (min-width: ${breakpoint.lg}px) {
    left: auto;
    right: 0;
    width: 75%;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const HeadingContent = styled.div`
  position: relative;
  bottom: 20px;
  padding-left: 48px;
  max-width: 1366px;
  width: 100%;

  @media (min-width: ${breakpoint.md}px) {
    padding-left: 150px;
  }
`;

const Title = styled.h1`
  font-size: 42px;
  line-height: 1.1;
  display: inline-block;

  @media (min-width: ${breakpoint.lg}px) {
    font-size: 100px;
  }
`;

const Subtitle = styled.h2`
  font-family: ${styling.fontFamilyCopernicus};
  font-style: italic;
  font-weight: normal;
  font-size: 20px;
  margin-bottom: 0.3em;

  @media (min-width: ${breakpoint.lg}px) {
    font-size: 40px;
  }
`;

const Rule = styled.div`
  margin-top: 20px;
  border-bottom: 2px #4a76e4 solid;
  width: 55px;

  @media (min-width: ${breakpoint.lg}px) {
    margin-top: 90px;
  }
`;

const EmailAddress = styled.div`
  display: none;
  position: absolute;
  right: 30px;
  bottom: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.9;

  &::before {
    content: '';
    position: absolute;
    height: 2px;
    width: 55px;
    background-color: #fff;
    left: -80px;
    top: 13px;
  }

  @media (min-width: ${breakpoint.lg}px) {
    display: block;
  }
`;

const PoweredBy = styled.h5`
  color: #4a76e4;
  font-size: 14px;
  position: absolute;
  right: 50px;
  bottom: -37px;
  margin: 0;
  font-weight: normal;

  span {
    color: #fff;
  }

  @media (min-width: ${breakpoint.lg}px) {
    display: none;
  }
`;

const BornLogo = styled.img`
  width: 25px;
  height: 25px;
  position: absolute;
  display: inline-block;
  bottom: -4px;
  margin-left: 10px;
`;

export default class extends Component {
  constructor() {
    super();
    this.containerRef = createRef();
  }

  render() {
    return (
      <HeaderContainer ref={this.containerRef}>
        <BackgroundVideoContainer>
          <video src="https://newborn-website.ams3.cdn.digitaloceanspaces.com/static/homeHeaderVideo.mp4" autoPlay playsInline loop muted />
        </BackgroundVideoContainer>

        <HeadingContent>
          <Subtitle>Newborn is a venture studio</Subtitle>
          <Title>
            Accelerating
            <br />
            Innovation
          </Title>

          <Rule />

          <EmailAddress>
            <Link to="mailto:info@newborn.ventures">info@newborn.ventures</Link>
          </EmailAddress>
        </HeadingContent>

        <PoweredBy>
          An &nbsp;<span>Ace</span>&nbsp; agency
          <Link to="https://www.thisisace.nl">
            <BornLogo src={aceLogo} />
          </Link>
        </PoweredBy>
      </HeaderContainer>
    );
  }
}
