import { css } from 'styled-components';

const buttonReset = css`
  position: relative;
  border: 0;
  padding: 0;
  outline: 0;
  border-radius: 0;
  text-decoration: none;
  display: inline-block;
  font-family: inherit;
  font-weight: inherit;
  background: transparent;
  cursor: inherit;
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }

  &:disabled {
    opacity: 1;
  }
`;

export default buttonReset;
