import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
import styled from 'styled-components';

import breakpoint from '../constants/breakpoints';
import Principles from '../sections/principles/principlesSection';
import Approach from '../sections/approach/approachSection';
import About from '../sections/about/aboutSection';
import HeaderSection from '../sections/header/headerSection';
import Nav from '../sections/nav/navSection';
import Ventures from '../sections/slider/campaignSliderSection';
import Collaborate from '../sections/collaborate/collaborateSection';
import Contact from '../sections/contact/contactSection';
import CampaignOverlay from '../sections/campaignOverlay/campaignSection';
// import NewsModule from '../sections/news/NewsModule';
// import NewsOverlay from '../sections/news/NewsOverlay';
import Layout from '../layouts/index';

const HomepageContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${breakpoint.lg}px) {
    padding-right: 60px;
  }
`;

export default class Home extends Component {
  render() {
    return (
      <Layout>
        <HomepageContainer>
          <Nav />
          <HeaderSection />
          <About />
          <Ventures />
          <Principles />
          <Approach />
          <Collaborate />
          {/* <NewsModule /> */}
          <Contact />
          <CampaignOverlay />
          {/* <NewsOverlay /> */}
        </HomepageContainer>
      </Layout>
    );
  }
}
