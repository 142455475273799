export default function range(value, min = 0, max = 1) {
  return Math.max(min, Math.min(max, value));
}

export function rangeRotate(value, min = 0, max = 1) {
  let newVal = value;
  if (newVal > max) {
    newVal = min + (newVal - max) - 1;
  } else if (newVal < min) {
    newVal = max - (min - newVal) + 1;
  }

  return newVal;
}
