import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { h4 } from './typographyComponents';
import { openModalAction } from '../state/appReducer';

const Slide = styled.a`
  position: relative;
  height: 100%;
  width: 34vw;
  min-width: 303px;
  max-width: 496px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  img {
    transition: transform 0.5s ease-out;
    will-change: transform;
  }

  &:hover {
    cursor: pointer;

    img {
      transform: scale(1.05);
    }
  }
`;

const BackImage = styled.img`
  width: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  height: 50%;
  object-fit: contain;
  object-position: center;
  margin-bottom: 1rem;
`;

const Title = styled(h4)`
  font-size: 32px;
  line-height: 1.125;
`;

const Subtitle = styled.h6`
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 3px;
`;

const Fase = styled.p`
  font-size: 13px;
  line-height: 1.5;
  color: #303030;
  font-weight: bold;
`;

const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => {
    dispatch(openModalAction('campaign', data));
  },
});

class Card extends Component {
  static propTypes = {
    data: PropTypes.shape({
      slug: PropTypes.string,
      slideColor: PropTypes.string,
      textColor: PropTypes.string,
      img: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      processSteps: PropTypes.array,
    }),
    openModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: {},
  };

  constructor() {
    super();

    this.sliderClickHandler = this.sliderClickHandler.bind(this);
  }

  sliderClickHandler(e) {
    if (e) e.preventDefault();
    const { data, openModal } = this.props;
    openModal(data);
  }

  render() {
    const { data } = this.props;

    return (
      <Slide
        href={`#${data.slug}`}
        style={{
          backgroundColor: data.slideColor,
          color: data.textColor,
        }}
        onClick={this.sliderClickHandler}
      >
        <BackImage src={data.img} />

        <Title>{data.title}</Title>
        <Subtitle>{data.subtitle}</Subtitle>

        <Fase>
          Current phase &rarr; {data.processSteps[data.processSteps.length - 1]}
        </Fase>
      </Slide>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(Card);
