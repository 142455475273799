import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import breakpoint from '../constants/breakpoints';
import { p1, h5 } from './typographyComponents';

const TilesContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0 20px 0 40px;

  @media (min-width: ${breakpoint.md}px) {
    width: calc(100% - 200px);
    max-width: 500px;
  }

  @media (min-width: ${breakpoint.lg}px) {
    max-width: none;
    width: 100%;
    padding: 0 50px 0 150px;
    flex-direction: row;
  }
`;

const Tile = styled.div`
  flex: 1 0 50%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-right: 20px;

  @media (min-width: ${breakpoint.md}px) {
    flex-basis: 50%;
    max-width: 700px;
  }

  @media (min-width: ${breakpoint.lg}px) {
    flex-basis: 25%;
    flex-direction: column;
  }
`;

const TileGraphic = styled.img`
  width: 28px;
  margin-bottom: 15px;
`;

const ContentBody = p1;

const ContentTitle = styled(h5)`
  color: #ffffff;
  text-align: left;
  width: 100%;
`;

export default class extends Component {
  static propTypes = {
    graphicSrcs: PropTypes.arrayOf(PropTypes.string),
    contentTitles: PropTypes.arrayOf(PropTypes.string),
    contentBodies: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    graphicSrcs: [],
    contentTitles: [],
    contentBodies: [],
  };

  render = () => {
    const { graphicSrcs, contentTitles, contentBodies } = this.props;

    return (
      <TilesContainer>
        {graphicSrcs.map((src, i) => (
          <Tile key={i + 1}>
            <TileGraphic src={src} />
            <ContentTitle>{contentTitles[i]}</ContentTitle>
            <ContentBody>{contentBodies[i]}</ContentBody>
          </Tile>
        ))}
      </TilesContainer>
    );
  };
}
