import React, { Component } from 'react';
import styled from 'styled-components';

import ViewportParallax from '../../utils/ViewportParallax';
import breakpoint from '../../constants/breakpoints';
import { p2 } from '../../components/typographyComponents';
import letterGif from '../../../static/letterBgVideo.gif';
import MapBoxMap from '../../components/mapboxMap';
import ContentBody from '../../components/contentBody';
import Link from '../../components/link';
import AnimationLetter from '../../components/animationLetter';
import mapmarker from './mapMarker.png';

const ContactWrapper = styled.section`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 160px 0 140px 30px;

  @media (min-width: ${breakpoint.md}px) {
    max-width: 440px;
    padding: 160px 0 140px;
    margin: 0 100px;
    width: calc(100% - 200px);
  }

  @media (min-width: ${breakpoint.lg}px) {
    flex-direction: row;
    max-width: 1366px;
    padding: 100px 150px 70px;
    margin: 0;
    width: 100%;
  }
`;

const ContactInfo = styled.div`
  display: grid;
  padding: 50px 0;
  grid-template-columns: 100%;
  grid-gap: 16px 0;
  padding-top: 50px;

  @media (min-width: ${breakpoint.lg}px) {
    grid-template-columns: 50% 50%;
    grid-gap: 16px 0;
    grid-auto-flow: column;
    grid-template-rows: 1fr 1fr 1fr;
  }
`;

const MapContainer = styled.div`
  width: 100%;
  height: 200px;

  @media (min-width: ${breakpoint.lg}px) {
    height: 260px;
    margin-top: 80px;
  }
`;

const ContactLetterContainer = styled.div`
  position: absolute;
  width: auto;
  height: 100%;
  top: -30px;
  right: -30px;
  left: 0;
  display: flex;
  justify-content: center;
  padding-top: 13px;

  @media (min-width: ${breakpoint.lg}px) {
    left: auto;
    top: 0;
    right: 0;
    padding-right: 35px;
    padding-top: 45px;
    width: 54%;
  }

  &::before {
    content: '';
    position: absolute;
    background-color: #fff;
    width: 100vw;
    height: 80px;
    top: 0;
    left: 0;

    @media (min-width: ${breakpoint.lg}px) {
      height: 340px;
    }
  }
`;

const Signature = styled.div`
  position: absolute;
  bottom: 6%;
  right: 6%;
  font-size: 12px;

  @media (min-width: ${breakpoint.lg}px) {
    bottom: 63px;
    right: 70px;
    width: 145px;
  }

  hr {
    display: none;
    width: 42px;
    margin: 4px 0 4px 19px;

    @media (min-width: ${breakpoint.lg}px) {
      display: inline-block;
    }
  }
`;

const ContactLetterWrapper = styled.div`
  max-width: 490px;
  width: 100%;
  left: -14px;
  position: relative;
`;

const ContactInfoAddr = styled(p2.withComponent('address'))`
  font-style: normal;
`;
const ContactInfoGen = p2;
const ContactInfoLine = styled(p2)`
  opacity: 0;

  @media (min-width: ${breakpoint.lg}px) {
    opacity: 1;
  }
`;

export default class ContactSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      intro: false,
    };

    this.introHandler = this.introHandler.bind(this);
  }

  introHandler() {
    this.setState({
      intro: true,
    });
  }

  render() {
    const { intro } = this.state;
    return (
      <ViewportParallax introHandler={this.introHandler}>
        {(elementRef) => (
          <ContactWrapper ref={elementRef} id="contact">
            <ContactLetterContainer>
              <ContactLetterWrapper>
                <AnimationLetter
                  bgImg={letterGif}
                  uniqueId="contactLetter"
                  letter="T"
                  show={intro}
                />
              </ContactLetterWrapper>
            </ContactLetterContainer>

            <ContactContainer>
              <ContentBody
                index="06"
                title={["Let's get", 'in touch']}
                subtitle="Call or email us"
                show={intro}
              >
                <ContactInfo>
                  <ContactInfoGen>
                    <Link href="mailto:info@newborn.ventures">
                      info@newborn.ventures
                    </Link>
                  </ContactInfoGen>

                  <ContactInfoGen>
                    <Link href="tel:+31(0)30 280 70 30">
                      +31(0)30 280 70 30
                    </Link>
                  </ContactInfoGen>

                  <ContactInfoLine>__________</ContactInfoLine>
                  <ContactInfoAddr>Maliesingel 28A</ContactInfoAddr>
                  <ContactInfoAddr>3581 BH, Utrecht</ContactInfoAddr>
                  <ContactInfoAddr>The Netherlands</ContactInfoAddr>
                </ContactInfo>

                <MapContainer>
                  <MapBoxMap markerImg={mapmarker} />
                </MapContainer>
              </ContentBody>
            </ContactContainer>

            <Signature>
              <span>Newborn 2019</span>
              <hr />
            </Signature>
          </ContactWrapper>
        )}
      </ViewportParallax>
    );
  }
}
