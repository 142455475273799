import React, { Component } from 'react';
import styled from 'styled-components';

import ViewportParallax from '../../utils/ViewportParallax';
import breakpoint from '../../constants/breakpoints';
import ContentBody from '../../components/contentBody';
import Timeline from '../../components/timeline';
import icon0 from './icons/explore.svg';
import icon1 from './icons/validate.svg';
import icon2 from './icons/prototype.svg';
import icon3 from './icons/build.svg';
import icon4 from './icons/grow.svg';
import icon5 from './icons/scaling-up.svg';

const TimelineWrapper = styled.section`
  width: 100%;
  background-color: #4a76e4;
  display: flex;
  justify-content: center;
`;

const ContentBodyWrapper = styled.div`
  margin-bottom: 50px;
`;

const TimelineContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 50px 30px;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${breakpoint.md}px) {
    margin: 50px 100px;
    max-width: 500px;
    padding: 0;
  }

  @media (min-width: ${breakpoint.lg}px) {
    padding: 70px 150px;
    margin: 0;
    min-height: 60vh;
    max-width: 1366px;
    align-items: flex-start;
    justify-content: center;
  }
`;

export default class ApproachSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      intro: false,
    };

    this.introHandler = this.introHandler.bind(this);
  }

  introHandler() {
    this.setState({
      intro: true,
    });
  }

  render() {
    const { intro } = this.state;
    return (
      <ViewportParallax introHandler={this.introHandler}>
        {(elementRef) => (
          <TimelineWrapper ref={elementRef} id="approach">
            <TimelineContainer>
              <ContentBodyWrapper>
                <ContentBody
                  index="04"
                  subtitle="From promising idea to scalable startup"
                  title={['Our approach']}
                  align="left"
                  bgColor="74, 118, 228"
                  show={intro}
                />
              </ContentBodyWrapper>
              <Timeline
                graphicSrcs={[icon0, icon1, icon2, icon3, icon4, icon5]}
                contentTitles={[
                  'Explore',
                  'Validate',
                  'Prototype',
                  'Build',
                  'Grow',
                  'Scaling-up',
                ]}
              />
            </TimelineContainer>
          </TimelineWrapper>
        )}
      </ViewportParallax>
    );
  }
}
