import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import { TweenLite } from 'gsap';

import ViewportParallax from '../../utils/ViewportParallax';
import breakpoint from '../../constants/breakpoints';
import TitleComponent from '../../components/contentBody';
import Card from '../../components/sliderCard';
import { EASE_OUT } from '../../Constants';
import sliderData from '../../data/sliderData';

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  display: block;
  padding-left: 30px;

  @media (min-width: ${breakpoint.md}px) {
    padding: 0;
    width: calc(100% - 200px);
    max-width: 500px;
    margin: 0 100px;
  }

  @media (min-width: ${breakpoint.lg}px) {
    margin: 0;
    width: 100%;
    max-width: 1366px;
    position: static;
    padding-left: 150px;
  }

  @media (min-width: ${breakpoint.xxl}px) {
    position: relative;
  }
`;

const Slider = styled.div`
  position: relative;
  height: 475px;
  overflow: hidden;
  display: block;
`;

const SliderRail = styled.div`
  position: absolute;
  height: 100%;
  transform: translateX(100vw);
  will-change: transform;
  display: flex;
`;

const SliderControlRight = styled.svg`
  position: absolute;
  right: -1px;
  top: 335px;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: ${breakpoint.lg}px) {
    top: 485px;
  }

  @media (min-width: ${breakpoint.xxl}px) {
    top: 375px;
  }

  path {
    fill: #000;

    @media (min-width: ${breakpoint.md}px) {
      fill: #fff;
    }

    @media (min-width: ${breakpoint.lg}px) {
      fill: #000;
    }
  }

  polyline {
    stroke: #fff;

    @media (min-width: ${breakpoint.md}px) {
      stroke: #000;
    }

    @media (min-width: ${breakpoint.lg}px) {
      stroke: #fff;
    }
  }
`;

const SliderControlLeft = styled.svg`
  position: absolute;
  left: -1px;
  top: 335px;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: ${breakpoint.lg}px) {
    top: 485px;
  }

  @media (min-width: ${breakpoint.xxl}px) {
    top: 375px;
    left: 149px;
  }

  path {
    fill: #fff;

    @media (min-width: ${breakpoint.xxl}px) {
      fill: #000;
    }
  }

  polyline {
    stroke: #000;

    @media (min-width: ${breakpoint.xxl}px) {
      stroke: #fff;
    }
  }
`;

const SliderWrapper = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  @media (min-width: ${breakpoint.lg}px) {
    padding-top: 130px;
  }

  &::before {
    content: '';
    position: absolute;
    background-color: #ffffff;
    height: 400px;
    left: 0;
    right: 0;
    top: 0;

    @media (min-width: ${breakpoint.md}px) {
      top: -10px;
      height: 485px;
    }

    @media (min-width: ${breakpoint.lg}px) {
      height: 474px;
    }
  }
`;

const SliderTitleContainer = styled.div`
  padding: 55px 0 27px;
`;

export default class CampaignSliderSection extends Component {
  cardElements = [];

  currentIndex = 0;

  constructor(props) {
    super(props);
    this.cards = {};
    this.sliderRailEl = createRef();
    this.sliderContainerEl = createRef();

    this.state = {
      intro: false,
      scrollable: true,
    };

    this.introHandler = this.introHandler.bind(this);
  }

  componentDidMount() {
    // Gather element information
    this.calculateSliderSizes();
  }

  onButtonClickEventHandler(direction, e) {
    if (e) e.preventDefault();

    // check if sliding is possible. If so, update current index.
    if (direction === 'left' && this.currentIndex > 0) {
      this.currentIndex -= 1;
    } else if (
      sliderData.length - this.slidesFullyVisible - this.currentIndex ===
      1
    ) {
      this.animateToPosition(null, (this.railWidth - this.containerWidth) * -1);
      this.currentIndex += 1;
      return;
    } else if (
      direction === 'right' &&
      sliderData.length - this.slidesFullyVisible - this.currentIndex > 1 &&
      this.lastSliderRightPos > this.sliderContainerRightPos
    ) {
      this.currentIndex += 1;
    } else {
      return;
    }

    // Animate the slider to the new index position.
    this.animateToPosition(null, this.currentIndex * this.slideWidth * -1);
  }

  introHandler() {
    const { intro } = this.state;

    if (!intro) {
      this.initialAnimation();
    }

    this.setState({
      intro: true,
    });
  }

  calculateSliderSizes() {
    if (!this.sliderRailEl.current) return;

    this.lastSliderRightPos = this.sliderRailEl.current.getBoundingClientRect().right;
    this.sliderContainerRightPos = this.sliderContainerEl.current.getBoundingClientRect().right;
    this.containerWidth = this.sliderContainerEl.current.offsetWidth;
    this.railWidth = this.sliderRailEl.current.offsetWidth;
    this.slideWidth = this.railWidth / sliderData.length;
    this.slidesFullyVisible = Math.floor(this.containerWidth / this.slideWidth);
    this.maxIndex = sliderData.length - this.slidesFullyVisible;

    const { scrollable } = this.state;

    if (this.containerWidth >= this.railWidth) {
      this.setState(() => ({ scrollable: false }));
    } else if (!scrollable) {
      this.setState(() => ({ scrollable: true }));
    }
  }

  touchMoveHandler(e) {
    // While de client is dragging, update the X position of
    // the slider rail to the position of the finger.
    this.newXpos =
      this.currentTranslate * -1 - (this.touchStartPos - e.touches[0].clientX);

    if (this.newXpos > 0) {
      this.sliderRailEl.current.style.transform = `translateX(${0}px)`;
    } else if (this.newXpos < this.railWidth * -1) {
      this.sliderRailEl.current.style.transform = `translateX(${this.railWidth *
        -1}px)`;
    } else {
      this.sliderRailEl.current.style.transform = `translateX(${
        this.newXpos
      }px)`;
    }
  }

  touchStartHandler(e) {
    // Gather current slider position information when
    // dragging starts.
    this.currentTranslate = this.sliderRailEl.current.style.transform
      .match(/\d/g)
      .join('');
    this.touchStartPos = e.touches[0].clientX;
  }

  touchEndHandler() {
    // Calculate new current position of the slider after dragging stops.
    if (this.newXpos > 0) {
      this.currentIndex = 0;
      this.animateToPosition(0, this.currentIndex * this.slideWidth * -1);
    } else if (this.newXpos < this.railWidth * -1) {
      this.currentIndex = sliderData.length - 1;
      this.animateToPosition(
        this.newXpos,
        this.currentIndex * this.slideWidth * -1,
      );
    } else {
      this.currentIndex = Math.min(
        Math.max(Math.round(Math.abs(this.newXpos) / this.slideWidth), 0),
        sliderData.length - 1,
      );
      this.animateToPosition(
        this.newXpos,
        this.currentIndex * this.slideWidth * -1,
      );
    }
  }

  animateToPosition(from, to) {
    if (from) {
      TweenLite.fromTo(
        this.sliderRailEl.current,
        0.7,
        {
          x: `${from}px`,
        },
        {
          x: `${to}px`,
          ease: EASE_OUT,
          onComplete: () => {
            this.lastSliderRightPos = this.sliderRailEl.current.getBoundingClientRect().right;
          },
        },
      );
    } else {
      TweenLite.to(this.sliderRailEl.current, 0.7, {
        x: `${to}px`,
        ease: EASE_OUT,
        onComplete: () => {
          this.lastSliderRightPos = this.sliderRailEl.current.getBoundingClientRect().right;
        },
      });
    }
  }

  initialAnimation() {
    TweenLite.to(this.sliderRailEl.current, 2.08, {
      x: '0vw',
      ease: EASE_OUT,
    });
  }

  render() {
    const { intro, scrollable } = this.state;
    return (
      <ViewportParallax introHandler={this.introHandler}>
        {(elementRef) => (
          <SliderWrapper ref={elementRef} id="ventures">
            <SliderContainer>
              <SliderTitleContainer>
                <TitleComponent
                  index="02"
                  title={['Our ventures']}
                  subtitle="Current investments"
                  textColor="0,0,0"
                  bgColor="255,255,255"
                  show={intro}
                />
              </SliderTitleContainer>

              <Slider ref={this.sliderContainerEl}>
                <SliderRail ref={this.sliderRailEl}>
                  {sliderData.map((slideData, i) => (
                    <Card index={i} key={i} data={slideData} />
                  ))}
                </SliderRail>
              </Slider>

              <SliderControlRight
                style={{ display: scrollable ? 'block' : 'none' }}
                onClick={this.onButtonClickEventHandler.bind(this, 'right')}
                width="53px"
                height="142px"
                viewBox="0 0 53 142"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <g>
                  <path d="M53,0 L53,141 C50.8828948,125.042807 37.4553397,116.456044 30.770546,113.123398 C12.8912477,107.179947 0,90.3427166 0,70.5 C0,50.6572834 12.8912477,33.8200525 30.770546,27.8766022 C37.4553397,24.5439563 50.8828948,15.9571927 53,0 Z" />
                  <polyline
                    fill="none"
                    strokeWidth="3"
                    points="23 65 29 70.5 23 76"
                  />
                </g>
              </SliderControlRight>

              <SliderControlLeft
                style={{ display: scrollable ? 'block' : 'none' }}
                onClick={this.onButtonClickEventHandler.bind(this, 'left')}
                width="53px"
                height="141px"
                viewBox="0 0 53 141"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <g>
                  <path
                    d="M53,0 L53,141 C50.8828948,125.042807 37.4553397,116.456044 30.770546,113.123398 C12.8912477,107.179947 0,90.3427166 0,70.5 C0,50.6572834 12.8912477,33.8200525 30.770546,27.8766022 C37.4553397,24.5439563 50.8828948,15.9571927 53,0 Z"
                    transform="translate(26.500000, 70.500000) scale(-1, 1) translate(-26.500000, -70.500000) "
                  />
                  <polyline
                    fill="none"
                    strokeWidth="3"
                    transform="translate(27.000000, 70.500000) scale(-1, 1) translate(-27.000000, -70.500000) "
                    points="24 65 30 70.5 24 76"
                  />
                </g>
              </SliderControlLeft>
            </SliderContainer>
          </SliderWrapper>
        )}
      </ViewportParallax>
    );
  }
}
