import React, { Component } from 'react';
import styled from 'styled-components';

import ViewportParallax from '../../utils/ViewportParallax';
import breakpoint from '../../constants/breakpoints';
import styling from '../../constants/styling';
import { THICK_C_PATH } from '../../Constants';
// import { p2, h2, h5 } from '../../components/typographyComponents';
import letterGif from '../../../static/letterBgVideo.gif';
import TitleComponent, {
  Body as Paragraph,
} from '../../components/contentBody';
import { h5 } from '../../components/typographyComponents';
import AnimationLetter from '../../components/animationLetter';
// import VerticalList from '../../components/verticalList';

const CollaborateWrapper = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #ffffff;
  position: relative;
  padding-bottom: 40px;
`;
const CollaborateContainer = styled.div`
  width: 100%;
  max-width: 1366px;
  position: relative;

  @media (min-width: ${breakpoint.md}px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  @media (min-width: ${breakpoint.lg}px) {
    padding: 0 50px 0 150px;
    position: static;
  }
`;

const CollaborateContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  position: relative;
  padding: 305px 30px 0;

  @media (min-width: ${breakpoint.md}px) {
    padding: 190px 0 0;
    width: calc(100% - 200px);
    margin: 0 100px;
    max-width: 500px;
    position: static;
  }

  @media (min-width: ${breakpoint.lg}px) {
    width: 100%;
    margin: 0;
    max-width: 100%;
    padding: 140px 0;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: row;
    position: relative;
  }
`;

const CollLetterContainer = styled.div`
  position: absolute;
  width: 380px;
  top: 27px;
  left: -40px;
  display: flex;
  justify-content: center;

  @media (min-width: ${breakpoint.lg}px) {
    width: 47%;
    right: auto;
    top: 140px;
    left: -60px;
  }
`;

const ContentTitle = styled(h5)`
  padding: 0;
`;

const StyledParagraph = styled(Paragraph)`
  margin-bottom: 1rem;
`;

const Button = styled.a`
  display: inline-block;
  background: ${styling.blue};
  color: ${styling.white};
  text-decoration: none;
  font-weight: bold;
  padding: 13px 30px;
`;

export default class CollaborateSection extends Component {
  listData = [
    {
      text: 'Become part of the Newborn startup program.',
    },
    {
      text: 'Start with our custom-made growth assessment.',
    },
    {
      text:
        'Find the core, context and the status of your product with this cooperative workshop.',
    },
    {
      text:
        'Gather ideation and insights, validate risky assumptions and potential success drivers, and define metrics.',
    },
    {
      text:
        'Select a lean and mean validation method together, one that best fits both our needs.',
    },
    {
      text:
        'All metrics will be closely monitored and reported. We celebrate success and failure.',
    },
    {
      text:
        'This results in a validated value proposition, definition of the MVP and a solid business case.',
    },
    {
      text:
        'This program will help to create focus, validate risks and potentials, generate growth and valuable insights.',
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      intro: false,
    };

    this.introHandler = this.introHandler.bind(this);
  }

  introHandler() {
    this.setState({
      intro: true,
    });
  }

  render() {
    const { intro } = this.state;
    return (
      <ViewportParallax introHandler={this.introHandler}>
        {(elementRef) => (
          <CollaborateWrapper ref={elementRef} id="program">
            <CollaborateContainer>
              <CollaborateContent>
                <CollLetterContainer>
                  <AnimationLetter
                    show={intro}
                    bgImg={letterGif}
                    uniqueId="collaborateLetter"
                    letter="P"
                  >
                    <path d={THICK_C_PATH} />
                  </AnimationLetter>
                </CollLetterContainer>

                <TitleComponent
                  index="05"
                  title={['Startup Program']}
                  subtitle="Take it to the next level"
                  textColor="0,0,0"
                  bgColor="255,255,255"
                  show={intro}
                >
                  <StyledParagraph>
                    Our program is designed to help you maximize success and
                    avoid common pitfalls. The first step is our growth session
                    to get to the core of your idea and venture. After this,
                    we’re all set to create an effective growth strategy with
                    tested insights and propositions, shaping the right focus
                    and roadmap for your product and business. Backed up by our
                    program, you can build a solid business foundation without
                    missing a crucial step, sustaining long-term success.
                  </StyledParagraph>

                  <ContentTitle>Interested?</ContentTitle>

                  <StyledParagraph>
                    We are always on the lookout for fresh ideas. If you think
                    we could be a good match, please fill out our contact form
                    and you will hear from us soon.
                  </StyledParagraph>

                  <Button
                    href="https://b05.typeform.com/to/KVbbNz"
                    target="_blank"
                  >
                    Contact us
                  </Button>
                </TitleComponent>
              </CollaborateContent>
            </CollaborateContainer>
          </CollaborateWrapper>
        )}
      </ViewportParallax>
    );
  }
}
