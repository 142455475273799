import styled from 'styled-components';

export const CrossButton = styled.span`
  position: absolute;
  display: inline-block;
  width: 30px;
  height: 30px;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 2px;
    margin-top: -2px;
    width: 100%;
    top: 50%;
    left: 0;
    background: #fff;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

export const HamburgerButton = styled.span`
  position: absolute;
  display: inline-block;
  width: 30px;
  height: 30px;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  &::before,
  &::after,
  span {
    content: '';
    position: absolute;
    height: 2px;
    width: 84%;
    left: 8%;
    background: #fff;
  }

  &::before {
    top: 6px;
  }

  span {
    top: 13px;
  }

  &::after {
    bottom: 8px;
  }
`;
