import React, { Component } from 'react';
import styled from 'styled-components';
import breakpoint from '../../constants/breakpoints';
import newbornLogoSmall from './newbornLogoSmall.png';

const NewbornIcon = styled.img`
  width: 60px;
  height: 60px;

  @media (min-width: ${breakpoint.md}px) {
    width: 80px;
    height: 80px;
  }
`;

const IconContainer = styled.div`
  width: 100%;
  pointer-events: none;
  padding: 30px;
`;

export default class extends Component {
  render() {
    return (
      <IconContainer>
        <NewbornIcon src={newbornLogoSmall} />
      </IconContainer>
    );
  }
}
