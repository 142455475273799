import React, { Component } from 'react';
import styled from 'styled-components';

import ViewportParallax from '../../utils/ViewportParallax';
import breakpoint from '../../constants/breakpoints';
import AnimationLetter from '../../components/animationLetter';
import ContentBody from '../../components/contentBody';
import GraphicTiles from '../../components/graphicTiles';
import letterGif from '../../../static/letterBgVideo.gif';
import icon0 from './icons/digital-focussed.png';
import icon1 from './icons/pioneership.png';
import icon2 from './icons/chemistry.png';
import icon3 from './icons/scaleability.png';
import icon4 from './icons/problem-solution-fit.png';
import icon5 from './icons/dedicated-team.png';
import icon6 from './icons/growing-markets.png';
import icon7 from './icons/investment.png';

const PrinciplesContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  align-items: center;

  @media (min-width: ${breakpoint.lg}px) {
    padding: 120px 0;
    max-width: 1366px;
  }
`;

const PrinciplesContent = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  max-width: 100%;
  padding: 72px 40px 30px;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: column;
  margin: 0;

  @media (min-width: ${breakpoint.md}px) {
    padding: 66px 40px 60px 0;
    width: calc(100% - 200px);
    max-width: 500px;
    margin: 0 100px;
  }

  @media (min-width: ${breakpoint.lg}px) {
    padding: 0 50px 40px 150px;
    width: 100%;
    max-width: none;
    margin: auto;
    flex-direction: row-reverse;
    align-items: center;
  }
`;

const PrinLetterContainer = styled.div`
  width: 370px;
  position: absolute;
  right: -90px;
  top: 0;

  @media (min-width: ${breakpoint.lg}px) {
    position: relative;
    width: 520px;
    right: auto;
    top: 0;
    left: -36px;
  }
`;

export default class PrinciplesSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      intro: false,
    };

    this.introHandler = this.introHandler.bind(this);
  }

  introHandler() {
    this.setState({
      intro: true,
    });
  }

  render() {
    const { intro } = this.state;
    return (
      <ViewportParallax introHandler={this.introHandler}>
        {(elementRef) => (
          <PrinciplesContainer ref={elementRef} id="principles">
            <PrinciplesContent>
              <PrinLetterContainer>
                <AnimationLetter
                  show={intro}
                  bgImg={letterGif}
                  uniqueId="principlesLetter"
                  letter="O"
                />
              </PrinLetterContainer>
              <ContentBody
                index="03"
                title={['Our principles']}
                subtitle="Requirements for shared success"
                body="To grow an idea into a successful company, true dedication is essential. We fully commit ourselves and we put everything into action to generate success together. This is how we assess business plans, ideas and proposals, before we decide to invest in it."
                show={intro}
              />
            </PrinciplesContent>
            <GraphicTiles
              graphicSrcs={[
                icon0,
                icon1,
                icon2,
                icon3,
                icon4,
                icon5,
                icon6,
                icon7,
              ]}
              contentTitles={[
                'Digital focussed',
                'Pioneership',
                'Chemistry',
                'Scaleability',
                'Problem solution fit',
                'Dedicated team',
                'Growing markets',
                'Investment',
              ]}
              contentBodies={[
                'Is what we do best',
                'Shaping  future routines',
                'Complimentary team',
                'Growth potential',
                'The foundation',
                'Getting things done',
                'Reachable audiences',
                'Early or midstage startups',
              ]}
            />
          </PrinciplesContainer>
        )}
      </ViewportParallax>
    );
  }
}
