/* eslint-disable react/no-children-prop */

import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';

import range from './range';
import { ViewportContext } from './ViewportProvider';

class ViewportParallax extends PureComponent {
  static contextType = ViewportContext;

  static propTypes = {
    children: PropTypes.func.isRequired,
    resetHandler: PropTypes.func,
    resizeHandler: PropTypes.func,
    scrollHandler: PropTypes.func,
    introHandler: PropTypes.func,
    outroHandler: PropTypes.func,
    removeOutroHandler: PropTypes.func,

    // scrollX: PropTypes.number.isRequired,
    scrollY: PropTypes.number.isRequired,
    windowWidth: PropTypes.number.isRequired,
    windowHeight: PropTypes.number.isRequired,

    isActive: PropTypes.bool,
  };

  static defaultProps = {
    resetHandler: null,
    resizeHandler: null,
    scrollHandler: null,
    introHandler: null,
    outroHandler: null,
    removeOutroHandler: null,
    isActive: true,
  };

  constructor(props) {
    super(props);

    this.top = 0;
    this.bottom = 0;
    this.outroProgress = 0;
    this.introProgress = 0;
    this.progress = 0;

    this.isShown = false;
    this.isOut = false;

    this.elementRef = createRef();
  }

  componentDidMount() {
    this.triggerHandlers();
  }

  componentWillReceiveProps(nextProps) {
    this.triggerHandlers(nextProps);
  }

  triggerHandlers(nextProps) {
    const {
      resetHandler,
      resizeHandler,
      scrollHandler,
      introHandler,
      outroHandler,
      removeOutroHandler,

      windowWidth,
      windowHeight,
      scrollY,
      isActive,
    } = this.props;

    /**
     * Trigger resize event.
     */
    if (
      !nextProps ||
      nextProps.windowWidth !== windowWidth ||
      nextProps.windowHeight !== windowHeight
    ) {
      if (resizeHandler) {
        resizeHandler(
          nextProps ? nextProps.windowWidth : windowWidth,
          nextProps ? nextProps.windowHeight : windowHeight,
        );
      }
    }

    if (!this.elementRef.current) return;
    this.calculateSize(nextProps || this.props);

    if (!this.isInViewport(nextProps || this.props)) {
      if (resetHandler && this.isShown && scrollY + windowHeight < this.top) {
        this.isShown = false;
        resetHandler();
      }

      return;
    }

    /**
     * Trigger scroll event.
     */
    if (
      (!nextProps ||
        nextProps.windowWidth !== windowWidth ||
        nextProps.windowHeight !== windowHeight ||
        nextProps.scrollY !== scrollY) &&
      (scrollHandler || introHandler)
    ) {
      this.calculatePosition(nextProps || this.props);

      if (scrollHandler) {
        scrollHandler(this.introProgress, this.outroProgress, this.progress);
      }
    }

    if (
      this.introProgress > 0.25 &&
      this.outroProgress < 0.75 &&
      introHandler &&
      !this.isShown &&
      isActive
    ) {
      this.isShown = true;
      introHandler();
    }

    if (this.outroProgress >= 1 && !this.isOut && outroHandler) {
      this.isOut = true;
      outroHandler();
    }

    if (this.outroProgress < 1 && this.isOut && removeOutroHandler) {
      this.isOut = false;
      removeOutroHandler();
    }
  }

  /**
   * Calculate element size.
   */
  calculateSize(props) {
    const el = this.elementRef.current;

    if (!el.getBoundingClientRect) return;

    const rect = el.getBoundingClientRect();

    this.top = rect.top + props.scrollY;
    this.bottom = rect.bottom + props.scrollY;
  }

  /**
   * Calculate scroll progress.
   */
  calculatePosition(props) {
    const { scrollY, windowHeight } = props;

    const start = this.top;
    const end = this.bottom;
    // const isIn = nextProps.scrollY + nextProps.windowHeigh > start;
    // const isOut = nextProps.scrollY > end;

    // Calculate the intro and outro progress.
    // The max limits components larger than the viewport.
    const scrollProgressMax = Math.min(end - start, props.windowHeight);
    const scrollOutroProgress = range(
      (props.scrollY - start) / scrollProgressMax,
    );
    const scrollIntroProgress = range(
      (scrollY + windowHeight - start) / scrollProgressMax,
    );
    // const scrollProgress = range(((1 - scrollIntroProgress) + scrollOutroProgress) / 2);
    const scrollProgress = range(
      (props.scrollY - (start - windowHeight)) / (end - (start - windowHeight)),
    );

    this.outroProgress = scrollOutroProgress;
    this.introProgress = scrollIntroProgress;
    this.progress = scrollProgress;
  }

  isInViewport(props) {
    const { scrollY, windowHeight } = props;

    return scrollY < this.bottom && scrollY + windowHeight > this.top;
  }

  render() {
    const { children } = this.props;

    // Passing our reference requires children as a function.
    return children(this.elementRef);
  }
}

/**
 * Binds the consumer to the ViewportContext component.
 */
const ViewportConsumer = ({
  resetHandler,
  resizeHandler,
  scrollHandler,
  introHandler,
  outroHandler,
  removeOutroHandler,
  children,
}) => (
  <ViewportContext.Consumer>
    {({ windowWidth, windowHeight, scrollX, scrollY }) => (
      <ViewportParallax
        resetHandler={resetHandler}
        resizeHandler={resizeHandler}
        scrollHandler={scrollHandler}
        introHandler={introHandler}
        outroHandler={outroHandler}
        removeOutroHandler={removeOutroHandler}
        windowWidth={windowWidth}
        windowHeight={windowHeight}
        scrollX={scrollX}
        scrollY={scrollY}
        children={children}
      />
    )}
  </ViewportContext.Consumer>
);

ViewportConsumer.propTypes = {
  children: PropTypes.func.isRequired,
  resetHandler: PropTypes.func,
  resizeHandler: PropTypes.func,
  scrollHandler: PropTypes.func,
  introHandler: PropTypes.func,
  outroHandler: PropTypes.func,
  removeOutroHandler: PropTypes.func,
};

ViewportConsumer.defaultProps = {
  resetHandler: null,
  resizeHandler: null,
  scrollHandler: null,
  introHandler: null,
  outroHandler: null,
  removeOutroHandler: null,
};

export default ViewportConsumer;
