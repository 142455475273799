import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { h5 } from './typographyComponents';
import breakpoint from '../constants/breakpoints';

const TilesContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: #4a76e4;

  @media (min-width: ${breakpoint.md}px) {
    width: 100%;
  }
`;

const TilesContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${breakpoint.md}px) {
    width: 280px;
  }

  @media (min-width: ${breakpoint.lg}px) {
    width: auto;
    display: grid;
    grid-template-columns: repeat(
      ${(p) => p.graphicSrcs.length}, ${(p) => 100 / p.graphicSrcs.length}%
    );
    align-items: flex-start;
  }
`;

const Tile = styled.div`
  display: flex;
  width: 100%;
  text-align: left;
  flex-direction: column;
  padding: 15px 0 25px 45px;
  align-items: center;
  max-width: 280px;

  @media (min-width: ${breakpoint.lg}px) {
    height: auto;
    max-width: none;
    width: 90%;
    flex-direction: column;
    justify-content: flex-start;
    padding: 15px 0 5px;
  }
`;

const TileGraphic = styled.img`
  height: 100px;
  width: 100%;
  max-width: 220px;
  object-fit: contain;
  object-position: center;
  margin-bottom: 20px;

  @media (min-width: ${breakpoint.lg}px) {
    height: 80px;
    margin-bottom: 0;
  }
`;

const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  flex-grow: 1;

  bottom: -24px;
  top: 28px;
  left: 0;

  @media (min-width: ${breakpoint.lg}px) {
    top: auto;
    bottom: 60px;
    left: auto;
    width: 100%;
    flex-direction: row;
    align-items: center;
  }

  &:before {
    content: '';
    position: absolute;
    background-color: #fff;
    bottom: 90px;
    top: 0;
    width: 4px;
    left: 8px;

    @media (min-width: ${breakpoint.lg}px) {
      height: 4px;
      width: 100%;
      left: auto;
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
    }
  }
`;

const LineCircle = styled.div`
  width: 20px;
  height: 20px;
  border: 4px solid #fff;
  border-radius: 50%;
  background-color: #4a76e4;
  position: relative;
`;

const LineEnding = styled.div`
  height: 20px;
  top: -75px;
  position: relative;
  right: -1px;

  @media (min-width: ${breakpoint.lg}px) {
    height: 30px;
    top: auto;
  }

  &:before,
  &:after {
    content: '';
    background-color: #fff;
    height: 4px;
    width: 15px;
    right: auto;
    position: absolute;

    @media (min-width: ${breakpoint.lg}px) {
      width: 20px;
      right: -3px;
    }
  }

  &:after {
    top: 0px;
    left: 7px;
    transform: rotate(-45deg);

    @media (min-width: ${breakpoint.lg}px) {
      top: 19px;
      left: auto;
    }
  }

  &:before {
    top: 0px;
    left: -2px;
    transform: rotate(45deg);

    @media (min-width: ${breakpoint.lg}px) {
      top: 7px;
      left: auto;
    }
  }
`;

const ContentTitle = styled(h5)`
  color: #ffffff;

  @media (min-width: ${breakpoint.md}px) {
    padding-bottom: 50px;
  }

  @media (min-width: ${breakpoint.lg}px) {
    padding-bottom: 0;
    padding-top: 80px;
  }
`;

export default class extends Component {
  static propTypes = {
    graphicSrcs: PropTypes.arrayOf(PropTypes.string),
    contentTitles: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    graphicSrcs: [],
    contentTitles: [],
  };

  render = () => {
    const { graphicSrcs, contentTitles } = this.props;

    return (
      <TilesContainer>
        <TilesContentContainer graphicSrcs={graphicSrcs}>
          <Timeline>
            {graphicSrcs.map((src) => (
              <LineCircle key={src} />
            ))}
            <LineEnding />
          </Timeline>

          {graphicSrcs.map((src, i) => (
            <Tile key={i + 1}>
              <TileGraphic src={src} />
              <ContentTitle>{contentTitles[i]}</ContentTitle>
            </Tile>
          ))}
        </TilesContentContainer>
      </TilesContainer>
    );
  };
}
